import * as React from 'react';
import { RouteComponentProps } from "react-router";
import tasks from '../../../tasks';

  interface UrlProps extends RouteComponentProps {
    taskId: number
  }

const Translation = (props: UrlProps) => {
    
    var taskFile = tasks[1][1640]
    console.debug("Loading task", taskFile)
    return(
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center'
        }}> 
        <span role='img'>⚠️</span>
            <h4>Unable to complete decoding!</h4>
            <h6>Manual intervation required</h6>
            <hr/>
            <div>
                <p>Recovered Data</p>
                <input readOnly={true} value={taskFile.source} style={{width: '100%', fontSize: 'larger'}}/>
            </div>
        </div>
    );
}

export default Translation;