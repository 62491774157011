import * as React from 'react';
import Typist from "react-typist";
import { AgentNameHook, BriefRunHook } from './hooks';
import { Redirect } from 'react-router';
//@ts-ignore
import hddClick from '../../public/effects/hddClick.mp3'

const briefText =
<p style={{display: "inline-block"}}>
    Cadets <br/>
    Our agents in the area have been compromised <br/>
    We require you to follow their tracks <br/>            
    Your device is being updated remotely follow its instructions and track down the intel from our agents! <br/>
    <br/>
    Good luck
    <br/>
    - USER IDENTIY WITHHELD -    
</p>

const briefTextInital = () => {
    const [agentName, setAgentName] = AgentNameHook();
    const [briefRun, setBriefRun] = BriefRunHook();
    const [hideInput, setHideInput] = React.useState(true);
    const [redirectTo, setRedirectTo] = React.useState("");
    if(redirectTo != ""){
        return <Redirect to={redirectTo}/>
    }
    return (    
        <div>
            <Typist avgTypingDelay={0} cursor={{element: "█"}} onTypingDone={() => {setHideInput(false); this.endElement.pause();}} onLineTyped={() => this.endElement.scrollIntoView({ behavior: "smooth" })}>
                <h4>Setting up secure connection...</h4>
                <p>Service Tunnel: OK <br/>
                Local Configuation: OK <br/>
                Remote Configuation: OK <br/>
                EXAs: OK</p>
                <h2>Secure Connection ACTIVE</h2>
                <hr/>
                <Typist.Delay ms={250} />
                <h3><span role="img">⚠️</span> Incoming connection</h3>
                {briefText}
                <h3><span role="img">⚠️</span> Connection lost</h3>
                <hr/>
                <div>
                        <span role="img">⚠️</span> Security Lockdown in effect! <br/>
                        ONLY ONE DEVICE MAY BE REGISTERED FOR THIS IDENTIY <br/>
                        THIS IDENTIY CAN NOT BE CHANGED
                </div>
                <h3>Device upgrade complete</h3>                
            </Typist>
            <p hidden={hideInput}>
                Enter agent identifer <br/>                
                <input value={agentName} type="text" onChange={e => setAgentName(e.target.value)}/> <button onClick={e => {setBriefRun(true); setRedirectTo("/task")}}>Submit</button>
            </p>
            <audio ref={c => (this.endElement = c)} src={hddClick} autoPlay></audio>            
        </div>
        );
}

const briefTextComplete = () => {
    const [agentName, setAgentName] = AgentNameHook();
    const [redirectTo, setRedirectTo] = React.useState("");
    if(redirectTo != ""){
        return <Redirect to={redirectTo}/>
    }
    return (
    <div>
        <h4>Mission Text</h4>
        {briefText}
        <hr/>
        <p>
            Registed agent identity: {agentName}
        </p>
        <button onClick={e =>{setRedirectTo("/task")}}>Access task scanner</button>
    </div>
)};

const Briefing = () => {
    const [briefRun, setBriefRun] = BriefRunHook();    
    if(briefRun){
        return briefTextComplete();
    }
    else{
        return briefTextInital();
    }
};

export default Briefing;