import * as React from 'react';
import QrReader from 'react-qr-reader'
import {Animated} from "react-animated-css";
import './rippleLoader';
import Typist from 'react-typist';
import { Redirect } from 'react-router';
//import './textWritter';

const SecurityDecoder = () => {
    const [isScanning, setIsScanning] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(false);
    const [redirectTo, setRedirectTo] = React.useState("");

    function codeScanned(codeData: string){
        if(codeData == null) return;
        console.info("Got QR code", codeData);
        setIsScanning(false);
        setIsLoading(true);
        if(!codeData.startsWith("Code:")){
            var el = document.getElementById("processingText")
            el.innerHTML = "<span role='img'>⚠️</span> Security Key REJECTED"
            setTimeout(() => {
                setIsScanning(true);
                setIsLoading(false);
                setTimeout(() => {
                    el.innerHTML = "Decrypting security key...";
                }, 500)                
            }, 1000)
            return;
        }
        var task = codeData.split(":");
        setRedirectTo("task/"+task[1] +"/"+task[2])
    }

    if(redirectTo != ""){
        return <Redirect to={redirectTo}/>
    }

    return(
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }}>            
            <Animated animationIn="zoomInDown" animationOut="zoomOutDown" animationInDuration={1000} animationOutDuration={1000} isVisible={isScanning}>
            <h5>Position security code in scanner</h5>
                <QrReader facingMode='environment' onLoad={() => console.debug("QrReader loaded")} onScan={data => codeScanned(data)} onError={ex => console.warn("Error loading QR code", ex)}/>
            </Animated>
            <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={400} animationOutDuration={400} isVisible={isLoading}>
                <p id="processingText" className="Text--blinking" ref={el => (this.processingText = el)}>
                    Decrypting security key...
                </p>
            </Animated>
        </div>
    );
}

export default SecurityDecoder;