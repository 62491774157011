import React, { Dispatch } from "react";

export const AgentNameHook = (): [string, Dispatch<string>] => {
  
    const [value, setValue] = React.useState(localStorage.getItem('agentName'));
    if(value == null) setValue("");
      React.useEffect(() => {
        localStorage.setItem('agentName', value);
        console.debug("Agent name set", value)
      }, [value]);
    
      return [value, setValue];
};

export const BriefRunHook = (): [boolean, Dispatch<boolean>] => {
    const [value, setValue] = React.useState(
        localStorage.getItem('runBrief') == "true"
      );
    
      React.useEffect(() => {
        localStorage.setItem('runBrief', value.toString());
      }, [value]);
    
      return [value, setValue];
};