import * as React from 'react';
import { Switch, Route } from 'react-router';
import Briefing from './Briefing';
import TaskWrapper from './TaskWrapper';
//@ts-ignore
import title0 from '../../public/music/title0.mp3'
//@ts-ignore
import title1 from '../../public/music/title1.mp3'
//@ts-ignore
import title2 from '../../public/music/title2.mp3'
//@ts-ignore
import title3 from '../../public/music/title3.mp3'

const App: React.SFC = () => {
    this.title0Ref = React.createRef();
    this.title1Ref = React.createRef();
    this.title2Ref = React.createRef();
    this.title3Ref = React.createRef();

    React.useEffect(() => {
        var audioArray = document.getElementsByClassName('songs');
        var i = 0;
        //@ts-ignore
        audioArray[i].volume = 0.5;
        //@ts-ignore
        audioArray[i].play();        
        for (i = 0; i < audioArray.length; ++i) {
            //@ts-ignore
            audioArray[i].volume = 0.5;
            audioArray[i].addEventListener('ended', function(e){
                var currentSong = e.target;
                console.debug("Music ended", e);
                var next = $(currentSong).nextAll('audio');
                console.debug("All tracks next", next);
                if (next.length) {
                    var nextAudio = $(next[0]);
                    console.debug("Playing next track", nextAudio)
                    nextAudio.trigger('play');
                }
                else{
                    console.debug("Reseting to begining")
                    //@ts-ignore
                    $(audioArray[0]).trigger('play');
                }
            });            
        }
    }, []);

var BriefingRender = <Briefing/>
    return (
        <main>
            <Switch>
                <Route path="/" exact component={() => BriefingRender}/>
                <Route path="/task" component={TaskWrapper}/>
            </Switch>
            <div>
            <audio ref={this.title0Ref} preload="auto" className="songs" src={title0}/>
            <audio ref={this.title1Ref} preload="auto" className="songs" src={title1}/>
            <audio ref={this.title2Ref} preload="auto" className="songs" src={title2}/>
            <audio ref={this.title3Ref} preload="auto" className="songs" src={title3}/>
            </div>
        </main>
        )
    };

export default App;