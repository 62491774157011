export default {
    1: {
        1640: {
            "source": ".--. . -. -. .- -. -",
            "answer": "pennant"
        },
        5209:{
            "source": ".--. . -. -. .- -. -",
            "answer": "pennant"
        }
    },
    2: {
        5243:{
            "text": [
                "I heard a keep note, affixal postering",
                "Eagerly I looked for the scrawl",
                "Much I marvelled the happy dartboard",
                "To warn me about the flyleaf"
            ]
        }
    }
}