import * as React from 'react';
import { Switch, Route } from "react-router";
import SecurityDecoder from "./Tasks/SecurityDecoder/SecurityDecoder";
import Translation from './Tasks/Translation/Translation';

const TaskWrapper = () => {

    return(
        <article>
                <Route path="/task" exact component={SecurityDecoder}/>
                <Route path="/task/1/:id" component={Translation}/>
        </article>
        
    );
}

export default TaskWrapper;